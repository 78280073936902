import Home from './pages/home/home';
import About from './pages/about/about';
import Career from './pages/career/career';
import Projects from './pages/projects/projects';
import Blog from './pages/blog/blog';
import Contact from './pages/contact/contact';
import NotFound from './pages/notFound/notFound';

// preciso de um item Blog antes de Contact
export const translations = {
  pt: {
    home: 'Início',
    about: 'Sobre',
    career: 'Carreira',
    projects: 'Projetos',
    blog: 'Blog',
    contact: 'Contato',
    fullContent: 'Conteúdo Completo',
    presentation: 'Apresentação',
    portfolio: '',
    notFound: 'Página não encontrada'
  },
  en: {
    home: 'Home',
    about: 'About',
    career: 'Career',
    projects: 'Projects',
    blog: 'Blog',
    contact: 'Contact',
    fullContent: 'Full Content',
    presentation: 'Presentation',
    portfolio: '',
    notFound: 'Page not found'
  }
};

const routesConfig = (language = 'pt') => {
  const currentTranslations = translations[language] || translations['pt'];
  return [
    { path: '/', title: currentTranslations.home, component: Home, showInNavbar: true },
    { path: '/about', title: currentTranslations.about, component: About, showInNavbar: true },
    { path: '/blog', title: currentTranslations.blog, component: Blog, showInNavbar: true },
    { path: '/portfolio', title: currentTranslations.portfolio, component: Home, showInNavbar: false },
    { path: '/projects', title: currentTranslations.projects, component: Projects, showInNavbar: true },
    { path: '/career', title: currentTranslations.career, component: Career, showInNavbar: true },
    { path: '/contact', title: currentTranslations.contact, component: Contact, showInNavbar: true },
    { path: '*', title: currentTranslations.notFound, component: NotFound, showInNavbar: false }
  ];
};

routesConfig.translations = translations; // Adiciona as traduções como propriedade exportável

export default routesConfig;
