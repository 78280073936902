// src/components/content/content.js
import React from 'react';
import MenuItems from '../menuItems/menuItems';
import './content.scss';
import SelectBox from '../selectBox/selectBox';

const Content = ({ children, menuItems, activeIndex, onMenuItemClick, onTypeChange, selectedType, contentTypes, menuReverse }) => {
    return (
        <div className='content-with-menu'>
            <div className='content-view'>
                {children}
            </div>
            <div className='content-menu'>
            <SelectBox options={contentTypes} selected={selectedType} onChange={onTypeChange} />
            {menuItems && <MenuItems
                items={menuItems}
                activeIndex={activeIndex}
                onItemClick={onMenuItemClick}
                menuReverse={menuReverse}
            />}
        </div></div>
    );
};

export default Content;
