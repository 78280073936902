// src/pages/notFound/notFound.js
import React, { useEffect, useState } from 'react';
import content from '../../data/notFoundMessages.json'; // JSON com as mensagens
import { useLanguage } from '../../context/languageContext'; // Importando o contexto
import './notFound.scss';

const NotFound = () => {
  const { language } = useLanguage(); // Usando o contexto de linguagem
  const [randomMessage, setRandomMessage] = useState({});

  useEffect(() => {
    const messages = content[language] || [];
    const randomIndex = Math.floor(Math.random() * messages.length);
    setRandomMessage(messages[randomIndex]);
  }, [language]);

  return (
    <div className="not-found-page">
      {/* {randomMessage.svg && (
        <img src={randomMessage.svg} alt="Not Found" className="not-found-svg" />
      )} */}
      <h2>{randomMessage.message}</h2>
    </div>
  );
};

export default NotFound;
