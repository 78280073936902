import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './nav.scss';
import Icon from '../icon/icon';
import Button from '../button/button';
import { applyTheme } from './../../utils/themeControler';
import { getUrlParams, setUrlParams } from '../../utils/urlParams';
import routesConfig from '../../routesConfig';
import { useLanguage } from '../../context/languageContext'; // Importando o contexto de linguagem

const Nav = ({ theme, setTheme, pageKey }) => {
  const { language, setLanguage } = useLanguage(); // Usando o contexto de linguagem
  const [navOpened, setNavOpened] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const navRef = useRef(null);

  // Atualizando o título da página conforme a rota
  const currentRoute = routesConfig(language).find(route => route.path === location.pathname);
  const pageTitle = currentRoute ? currentRoute.title : '';


// Fechar o nav ao clicar fora
useEffect(() => {
  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setNavOpened(false);
    }
  };

  document.addEventListener('mousedown', handleClickOutside);

  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

  // Alternar entre temas
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    applyTheme(newTheme);
    localStorage.setItem('theme', newTheme); // Salva a configuração
    setUrlParams({ theme: newTheme });
  };

  // Alternar entre linguagens
  const toggleLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem('language', lang);
    setUrlParams({ language: lang });
    navigate(`${location.pathname}?${new URLSearchParams({ language: lang }).toString()}`);
  };

  // Fechar o nav ao clicar fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setNavOpened(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Fechar o menu ao mudar de rota
  useEffect(() => {
    setNavOpened(false);
    document.title = pageTitle + ' - garaujo.space';
  }, [location.pathname]);

  return (
    <nav ref={navRef} className={`${navOpened ? 'nav-opened' : ''} ${pageKey}`}>
      <div className="nav-trigger">
        <div className="nav-name"><NavLink to={'/'}>garaujo</NavLink>.space</div>
        {pageTitle && <div className="nav-page-title">{pageTitle}</div>}
        <div className="nav-button btn only-icon" onClick={() => setNavOpened(!navOpened)}>
          {navOpened ? <Icon name="menu-collapse" /> : <Icon name="menu-expand" />}
        </div>
      </div>
      <div className={navOpened ? 'nav-options nav-options-opened' : 'nav-options'}>
        <ul>
          {routesConfig(language)
            .filter(route => route.showInNavbar)
            .map((route, i) => (
              <li className="nav-option" key={i}>
                <NavLink to={route.path}>
                  {route.title}
                </NavLink>
              </li>
            ))}
          <li className="nav-option button-list">
            <Button onlyIcon onClick={toggleTheme}>
              <Icon name={theme === 'light' ? 'moon' : 'sun'} />
            </Button>

            <Button onlyIcon onClick={() => toggleLanguage('en')}>
              EN
            </Button>
            <Button onlyIcon onClick={() => toggleLanguage('pt')}>
              PT
            </Button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
