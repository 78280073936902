import React from "react";
import './list.scss';

// if List receber prop vertical adiciona class list-vertical
const List = (props) => {
    return (
        <div className={props.vertical ? 'list list-vertical' : 'list'}>
            {props.children}
        </div>
    );
}

export default List