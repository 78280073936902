import React, { useEffect, useState } from 'react';
import Icon from '../../components/icon/icon';
import content from '../../data/career.json'; // Caminho para o arquivo JSON
import { useLanguage } from '../../context/languageContext'; // Importando o contexto
import Tag from '../../components/tag/tag';
import List from '../../components/list/list';
import './career.scss';

const Career = () => {
  const { language, setLanguage } = useLanguage(); // Usando o contexto
  const [data, setData] = useState([]);
  // const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Filtra o conteúdo da carreira com base no idioma
    setData(content[language] || []);
  }, [language]);

  // Verifica se data está definida antes de mapear
  if (!data || !Array.isArray(data.list)) {
    return <div className="loading">Loading...</div>; // Ou qualquer outra mensagem de carregamento
  }

  return (
    <List>
      {/* <h2>{data.title}</h2>
      <p className="description">{data.description}</p> */}
      {data.list.map((item, index) => (
        <div key={index}>
          <div>
          <h3>{item.year}</h3>
          {item.tags && item.tags.length > 0 && (
            <div className="tags-group">
              {item.tags.map((tag, tagIndex) => (
                <Tag key={tagIndex}>{tag}</Tag>
              ))}
            </div>
          )}
          <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
          {/* {item.links && item.links.length > 0 && (
            <div className="list-icons">
              {item.links.map((link, linkIndex) => (
                <div key={linkIndex}>
                  <a href={link.url} target="_blank" rel="noopener noreferrer">
                    {link.icon && <Icon name={link.icon} />}
                    {link.url_label && <span>{link.url_label}</span>}
                  </a>
                </div>
              ))}
            </div>
          )} */}
        </div>
        </div>
      ))}

    </List>
  );
};

export default Career;
