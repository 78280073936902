import React, { useEffect, useRef } from 'react';
import './SVGController.scss';

const SVGController = React.forwardRef(({ children, pulse }, svgRef) => {
  useEffect(() => {
    const svgElement = svgRef?.current;

    if (svgElement && svgElement instanceof SVGElement) {
      const pathElements = svgElement.querySelectorAll('path');
      const colorMap = {};

      pathElements.forEach((path, index) => {
        const fill = path.getAttribute('fill');
        if (fill) {
          if (!colorMap[fill]) {
            colorMap[fill] = [];
          }
          colorMap[fill].push(path);
        }
      });

      // Remove existing classes
      pathElements.forEach(path => path.classList.forEach(className => path.classList.remove(className)));

      // Add classes to paths based on color
      Object.keys(colorMap).forEach((color, index) => {
        colorMap[color].forEach((path, i) => {
          path.classList.add(`color-${index}`);
        });
      });

      console.log(colorMap);
    }
  }, [children, svgRef]);

  return (
    <div className='svg-container'>
      {React.cloneElement(children, {
        ref: svgRef,
      })}
    </div>
  );
});

SVGController.displayName = 'SVGController';

export default SVGController;
