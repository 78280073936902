const DateFormatter = ({ time }) => {
    const language = localStorage.getItem('language') || 'pt-BR';
  
    return (
      <span>
        {new Intl.DateTimeFormat(language, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          timeZoneName: 'short',
        }).format(new Date(time * 1000))}
      </span>
    );
  };
  
  export default DateFormatter;
  