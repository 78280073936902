import React, { useState } from 'react';
import Icon from '../icon/icon';
import './selectBox.scss';

const SelectBox = ({ options, selected, onChange = () => {}, widthEM }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (value) => {
    onChange(value); // Chama a função de mudança passada como prop
    setIsOpen(false);
  };

  // valida a quantidade de caracteres que os options tem, e grava em uma variável a maior
  const maxLength = Math.max(...options.map(option => option.length));

  return (
    <div className={isOpen ? 'select-box select-box__open' : 'select-box'} style={{ width: `${widthEM ? widthEM : maxLength + .8}em` }}>
      <div
        className="select-box__current"
        tabIndex="1"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="select-box__value">
          {selected}
        </div>
        <Icon name="chevron-down" />
      </div>
      {isOpen && (
        <ul className="select-box__list">
          {options.map((option, index) => (
            <li key={index}>
              <label
                className="select-box__option"
                onClick={() => handleOptionClick(option)}
                aria-hidden="true"
              >
                {option}
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectBox;
