export const applyTheme = (theme) => {
    const rootElement = document.documentElement;
    if (theme === 'light') {
        // Mudar para tema claro
        rootElement.style.setProperty('--color-bg', 'hsla(var(--hue), 15%, 95%, 1)');
        rootElement.style.setProperty('--color-bg-2', 'hsla(var(--hue), 15%, 90%, 1)');
        rootElement.style.setProperty('--color-bg-3', 'hsla(var(--hue), 15%, 85%, 1)');
        rootElement.style.setProperty('--color-text', 'hsla(var(--hue), 0%, 10%, 1)');
        rootElement.style.setProperty('--color-text-secondary', 'hsla(var(--hue), 0%, 20%, 0.666)');
    } else {
        // Mudar para tema escuro
        rootElement.style.setProperty('--color-bg', 'hsla(var(--hue), 0%, 14%, 1)');
        rootElement.style.setProperty('--color-bg-2', 'hsla(var(--hue), 0%, 22%, 1)');
        rootElement.style.setProperty('--color-bg-3', 'hsla(var(--hue), 0%, 28%, 1)');
        rootElement.style.setProperty('--color-text', 'hsla(var(--hue), 100%, 100%, 1)');
        rootElement.style.setProperty('--color-text-secondary', 'hsla(var(--hue), 100%, 100%, 0.666)');
    }
    localStorage.setItem('theme', theme); // Grava no local storage
};
