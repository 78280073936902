import Icon from "../icon/icon";
import "./button.scss";

const Button = (props) => {
    // Construindo a classe do botão com base nas props
    const buttonClass = `
        btn 
        ${props.href ? 'btn-url' : 'btn-action'} 
        ${props.onlyIcon ? 'only-icon' : ''} 
        ${props.selectable ? 'btn-selectable' : ''} 
        ${props.primary ? 'btn-primary' : ''} 
        ${props.crudeIcon ? 'crude-icon' : ''}
    `.trim();

    // Renderizando o botão com ou sem link
    return props.href ? (
        <a className={buttonClass} href={props.href} target='_blank' rel='noreferrer'>
            {props.children} 
            {props.external ? <Icon name='link' /> : null}
        </a>
    ) : (
        <button 
            title={props.title || ''} 
            className={buttonClass} 
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    );
}

export default Button;
