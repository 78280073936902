import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Nav from './components/nav/nav';
import Footer from './components/footer/footer';
import Container from './components/container/container';
import routesConfig from './routesConfig';
import { applyTheme } from './utils/themeControler';
import { useLanguage } from './context/languageContext';
import { BannerProvider } from './context/bannerContext';
import ScrollToTop from './utils/scroll-to';
import './App.scss';
import NavigationControl from './components/navigationControl/navigationControl';

function App() {
  const [theme, setTheme] = useState('dark');
  const { language, changeLanguage } = useLanguage();
  const location = useLocation();

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    const initialTheme = savedTheme || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    setTheme(initialTheme);
    applyTheme(initialTheme);
  }, []);

  // Obtém a chave da página com base na rota atual
  const getPageKey = () => {
    const route = routesConfig(language).find(route => route.path === location.pathname);
    if (!route) return '';

    // Encontrar a chave correspondente ao título
    const currentTranslations = Object.entries(routesConfig.translations[language]);
    const key = currentTranslations.find(([key, value]) => value === route.title)?.[0];
    return key || '';
  };

  return (
    <>
      <BannerProvider>
        <Nav pageKey={getPageKey()}  theme={theme} setTheme={setTheme} language={language} setLanguage={changeLanguage} />
        <Container pageKey={getPageKey()} footer={<Footer />}>
          <Routes>
            {routesConfig(language).map((route, index) => (
              <Route key={index} path={route.path} element={<route.component />} />
            ))}
          </Routes>
        </Container>
        <NavigationControl />
      </BannerProvider>
    </>
  );
}

export default App;
