import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../context/languageContext';
import { useLocation } from 'react-router-dom';
import Tag from '../../components/tag/tag';
import Content from '../../components/content/content';
import './blog.scss';
import DateFormatter from '../../utils/dateFormatter';

const Blog = () => {
  const { language } = useLanguage();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0); // Começa em 0, mas será ajustado para o último post
  const [selectedType, setSelectedType] = useState('all');
  const [contentTypes, setContentTypes] = useState([]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const contentTypeParam = params.get('content');

    const fetchedData = require('../../data/posts-and-updates.json');

    if (fetchedData && fetchedData.blog) {
      const typesSet = getContentTypes(fetchedData.blog);
      setContentTypes(['all', ...typesSet]);

      // Se o valor de `contentTypeParam` estiver no `typesSet`, o seleciona, caso contrário, mantém 'all'
      if (contentTypeParam && typesSet.includes(contentTypeParam)) {
        setSelectedType(contentTypeParam);
      }

      const processedData = fetchedData.blog.map(item => ({
        ...item,
        content_type: item.contentType,
      }));

      // Ordena os dados

      // const processedItems = menuReverse
      // ? [...items].reverse() // Inverte a ordem dos itens
      // : [...items]; // Mantém a ordem original

      // processedData.sort((a, b) => new Date(b.date) - new Date(a.date));

      setData(processedData.reverse());
      // console.log(processedData)

      // Define o índice ativo como o último post (mais recente)
      if (selectedType === 'all' || !contentTypeParam) {
        setActiveIndex(0); // Abre o mais recente
      }
    }
  }, [location.search]);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };
  
  const filteredData = selectedType === 'all'
    ?
    data
    :
    data.filter(item => item.content_type === selectedType);

  if (!language || data.length === 0) {
    return <div>Loading...</div>;
  }

  const handleTypeChange = (value) => {
    setSelectedType(value);
    // {console.log(filteredData[0].title["pt"])}
    setActiveIndex(0); // Reseta o índice ativo ao mudar o tipo
  };


  return (
    <div className='content'>
      <Content
        menuItems={filteredData.map(item => ({ title: item.title[language] }))}
        activeIndex={activeIndex}
        onMenuItemClick={handleItemClick}
        contentTypes={contentTypes}
        selectedType={selectedType}
        onTypeChange={handleTypeChange}
        menuReverse={true}
      >
        {filteredData.length > 0 && (
          <div className='content-view'>
            <h2>{filteredData[activeIndex].title[language]}</h2>
            <div className='content-info'>
              <DateFormatter time={filteredData[activeIndex].date} timezone={filteredData[activeIndex].timezone}></DateFormatter>
              <Tag>{filteredData[activeIndex].content_type}</Tag>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: filteredData[activeIndex].content[language],
              }}
            ></div>
          </div>
        )}
      </Content>
    </div>
  );
};

// Função para extrair os tipos de conteúdo únicos do fetchedData
const getContentTypes = (data) => {
  const contentTypes = new Set();
  
  data.forEach(item => {
    contentTypes.add(item.contentType);
  });

  return Array.from(contentTypes); // Retorna como array
};

export default Blog;
