export const getUrlParams = () => {
    const params = new URLSearchParams(window.location.search);
    return {
      theme: params.get('theme'),
      language: params.get('language')
    };
  };
  
  export const setUrlParams = (params) => {
    const currentParams = new URLSearchParams(window.location.search);
  
    for (const key in params) {
      if (params[key]) {
        currentParams.set(key, params[key]);
      } else {
        currentParams.delete(key);
      }
    }
  
    window.history.replaceState(null, '', `?${currentParams.toString()}`);
  };
  