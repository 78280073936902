import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from '../icon/icon';
import Button from '../button/button';
import routesConfig from '../../routesConfig';
import './navigationControl.scss';

const NavigationControl = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalRoutes, setTotalRoutes] = useState(0);

  useEffect(() => {
    // Atualiza o estado dos botões e o contador com base nas rotas disponíveis
    updateButtonStates();
  }, [location.pathname]);

  const updateButtonStates = () => {
    const routes = routesConfig().filter(route => route.showInNavbar).map(route => route.path);
    setTotalRoutes(routes.length);
    const currentRouteIndex = routes.indexOf(location.pathname);

    setCurrentIndex(currentRouteIndex);
    setIsPrevDisabled(currentRouteIndex <= 0);
    setIsNextDisabled(currentRouteIndex >= routes.length - 1);
  };

  const handleNext = () => {
    if (!isNextDisabled) {
      const routes = routesConfig().filter(route => route.showInNavbar).map(route => route.path);
      const currentRouteIndex = routes.indexOf(location.pathname);

      if (currentRouteIndex < routes.length - 1) {
        navigate(routes[currentRouteIndex + 1]);
      }
    }
  };

  const handlePrevious = () => {
    if (!isPrevDisabled) {
      const routes = routesConfig().filter(route => route.showInNavbar).map(route => route.path);
      const currentRouteIndex = routes.indexOf(location.pathname);

      if (currentRouteIndex > 0) {
        navigate(routes[currentRouteIndex - 1]);
      }
    }
  };

  return (
    <div className="navigation-control">
      <Button
        onlyIcon
        onClick={handlePrevious}
        disabled={currentIndex === 0}
      >
        <Icon name="skip-previous" />
      </Button>
      <span className="counter">{`${currentIndex + 1}/${totalRoutes}`}</span>
      <Button
        onlyIcon
        onClick={handleNext}
        disabled={isNextDisabled}
      >
        <Icon name="skip-next" />
      </Button>
    </div>
  );
};

export default NavigationControl;
