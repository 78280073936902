import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../context/languageContext'; // Importando o contexto
import BottomBanner from '../../components/bottomBanner/bottomBanner'; // Importa o componente BottomBanner
import { getUrlParams } from '../../utils/urlParams'; // Importa a função para ler parâmetros da URL
import content from '../../data/ui.json'; // Importa o conteúdo JSON
import './footer.scss';

const Footer = () => {
  const { language, setLanguage } = useLanguage(); // Usando o contexto
  const [data, setData] = useState({});
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Filtra o conteúdo do footer com base no idioma
    setData((content[language] && content[language][0]) || {});
  }, [language]);

  // Funções passadas para o BottomBanner
  const handleKeepLocal = () => {
    setShowBanner(false);
    localStorage.setItem('language', language); // Salva o idioma localmente
  };

  const handleUseUrl = () => {
    const urlParams = getUrlParams();
    if (urlParams.language) {
      setLanguage(urlParams.language); // Atualiza o contexto da linguagem
    }
    setShowBanner(false);
  };

  // Exibe o banner se o idioma no URL for diferente do idioma salvo localmente
  useEffect(() => {
    const urlParams = getUrlParams();
    const savedLanguage = localStorage.getItem('language') || 'pt';
    if (urlParams.language && urlParams.language !== savedLanguage) {
      setShowBanner(true);
    }
  }, []);

  // Verifica se data está definida e contém a mensagem do footer
  if (!data || !data['footer-message']) {
    return <div className="loading">Loading...</div>; // Ou qualquer outra mensagem de carregamento
  }

  return (
    <>
      <footer><small>{data['footer-message']} {new Date().getFullYear()}.</small></footer>
      <BottomBanner
        onKeepLocal={handleKeepLocal}
        onUseUrl={handleUseUrl}
        showBanner={showBanner}
        setShowBanner={setShowBanner}
      />
    </>
  );
};

export default Footer;
