import React from 'react';
import './tag.scss';

const Tag = ({ children, onClick, active, clickable, disabled, highlight = false },{...props}) => {
    let className = 'tag';
    if (clickable) className += ' clickable';
    if (active) className += ' tag-active';
    if (disabled) className += ' tag-disabled';
    if (highlight) className += ' tag-highlight';

    return (
        <span className={className} onClick={!disabled ? onClick : null} {...props}>
            {children}
        </span>
    );
};

export default Tag;
