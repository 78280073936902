import React, { useState, useEffect } from 'react';

const ViewportInfo = (props) => {
  const [topBarHeight, setTopBarHeight] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(window.visualViewport ? window.visualViewport.height : window.innerHeight);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [bottomBarHeight, setBottomBarHeight] = useState(0);
  const [bodyHeight, setBodyHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const viewportHeight = window.visualViewport ? window.visualViewport.height : window.innerHeight;
      const innerHeight = window.innerHeight;
      const topBarHeight = innerHeight - viewportHeight;
      const screenHeight = window.screen.height;

      const isMobile = /Mobi|Android/i.test(navigator.userAgent);
      const bottomBarHeight = screenHeight - innerHeight;

      setTopBarHeight(topBarHeight);
      setViewportHeight(viewportHeight);
      setInnerHeight(innerHeight);
      setBottomBarHeight(bottomBarHeight);

      setBodyHeight(isMobile ? viewportHeight - bottomBarHeight - topBarHeight : viewportHeight - topBarHeight);
    };

    handleResize(); // Obter valores iniciais
    window.visualViewport && window.visualViewport.addEventListener('resize', handleResize);

    return () => {
      window.visualViewport &&  window.visualViewport.removeEventListener('resize', handleResize);
    };
  }, []);

  // Se a prop bottomBarHeight for passada, retorna o valor numérico
  if (props.bottomBarHeight) {
    return bottomBarHeight;
  }

  return (
    // <div style={{ height: bodyHeight, width: '100%' }}>
    <div className='viewport-style'>
      {props.children}
    </div>
  );
};

export default ViewportInfo;
