import React from 'react';
import './menuItems.scss';

const MenuItems = ({ items, activeIndex, onItemClick, menuReverse }) => {

  if (!Array.isArray(items)) {
    return null; // Retorna nulo se items não for um array
  }

  return (
    <ul className={menuReverse ? 'menu-items menu-items-reverse' : 'menu-items'}>
      {items.map((item, index) => (
        <li
          key={index}
          className={activeIndex === index ? 'active' : ''}
          onClick={() => onItemClick(index)}
        >
          {item.title}
        </li>
      ))}
    </ul>
  );
};

export default MenuItems;
