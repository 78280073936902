import React, { useEffect, useState } from 'react';
import content from '../../data/list.json'; // Caminho para o arquivo JSON
import List from '../../components/list/list';
import { useLanguage } from '../../context/languageContext'; // Se o projeto for multilíngue
import Tag from '../../components/tag/tag';
import VideoYoutube from '../../components/videoYoutube/videoYoutube'; // Importando o componente de vídeo
import './projects.scss';

const Projects = () => {
  const { language, setLanguage } = useLanguage(); // Usando o contexto
  const [data, setData] = useState({});

  useEffect(() => {
    // Filtra o conteúdo com base no idioma, se necessário
    setData(content[language] || content.pt || {}); // Definir um fallback para o conteúdo
  }, [language]);

  // Verifica se data está definida antes de mapear
  if (!data.list || !Array.isArray(data.list)) {
    return <div className="loading">Loading...</div>; // Ou qualquer outra mensagem de carregamento
  }

  return (
    <>
      <List>
        {/* <h2>{data.title}</h2>
        <p className="description">{data.description}</p> */}

        <div className='list-projects'>
        {data.list.map((item, index) => (
          <div className='project' key={index}>
                
            <div className='list-item'>
          <div className='col-2'>
            {item.videoPreview && (
                  <VideoYoutube
                    videoId={item.videoYoutubeId}
                    videoPreview={item.videoPreview}
                    service={item.videoService}
                    linkPreview={item.links && item.links[0]?.url}
                  />
                )}
                {item.videoYoutubeId ? <h5>Video</h5> : ''}
                {!item.videoYoutubeId && item.videoPreview ? <h5>Preview</h5> : ''}
              </div>
              <h3>{item.title}</h3>
              <div className='col-1'>
                {item.tags && (
                  <div className="tags-group">
                    {item.status && <Tag highlight>{item.status}</Tag>}
                    {item.tags.map((tag, idx) => (
                      <Tag key={idx} className="tag">
                        {tag}
                      </Tag>
                    ))}
                  </div>
                )}
                <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                {item.links && item.links.length > 0 ? <h5>Related links</h5> : ''}
                <div className='list-icons'>
                  {item.links && item.links.map((link, idx) => (
                    <div key={idx}>
                      <a href={link.url} target="_blank" rel="noopener noreferrer">{link.url_label}</a>
                    </div>
                  ))}
                </div>
              </div>
              
            </div>
          </div>
        ))}
        </div>
      </List>
      
    </>
  );
};

export default Projects;
