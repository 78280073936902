import React, { createContext, useContext, useState, useEffect } from 'react';

const BannerContext = createContext();

export const BannerProvider = ({ children }) => {
  const [showBanner, setShowBanner] = useState(false);
  const [currentUrlParams, setCurrentUrlParams] = useState({});

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlLanguage = urlParams.get('language');
    const urlTheme = urlParams.get('theme');
    setCurrentUrlParams({ language: urlLanguage, theme: urlTheme });

    const localLanguage = localStorage.getItem('language');
    if (urlLanguage && urlLanguage !== localLanguage) {
      setShowBanner(true);
    }
  }, []);

  const handleSelect = (selection) => {
    const { language, theme } = currentUrlParams;

    if (selection === 'url') {
      if (language) localStorage.setItem('language', language);
      // Defina o tema se necessário
    }
    setShowBanner(false);
  };

  return (
    <BannerContext.Provider value={{ showBanner, handleSelect, setShowBanner }}>
      {children}
    </BannerContext.Provider>
  );
};

export const useBanner = () => useContext(BannerContext);
