import './videoYoutube.scss';
import { useState, useRef, useEffect } from 'react';
import Button from '../button/button';
import Icon from '../icon/icon';

const VideoYoutube = (props) => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const iframeRef = useRef(null);

    // URLs para YouTube e Loom
    const youtubeUrl = `https://www.youtube-nocookie.com/embed/${props.videoId}?rel=0&autoplay=1&mute=1`;
    const loomUrl = `https://www.loom.com/embed/${props.videoId}?hide_share=true&hideEmbedTopBar=true&hide_title=true&autoplay=true&muted=true`;

    const handleVideoLoad = () => {
        setIsVideoLoaded(true);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
          setIsVideoLoaded(false);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        isVideoLoaded
            ? (
                <div className='video-preview video-preview-open'>
                    <div className='video-modal'>
                        <Button onlyIcon crudeIcon onClick={() => setIsVideoLoaded(false)}>
                            <Icon name={"menu-collapse"} />
                        </Button>
                        <div className="video-container">
                            {props.service === "loom" ? (
                                <div>
                                    <iframe
                                        ref={iframeRef}
                                        src={loomUrl}
                                        frameBorder="0"
                                        allowFullScreen
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                    ></iframe>
                                </div>
                            ) : (
                                <iframe
                                    ref={iframeRef}
                                    width="100%"
                                    height="100%"
                                    src={youtubeUrl}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                ></iframe>
                            )}
                        </div>
                    </div>
                </div>
            )
            : 
              props.videoId ? 
                <div
                    onClick={handleVideoLoad}
                    className={`video-preview`}
                    style={props.videoPreview ? { backgroundImage: `url(${props.videoPreview})` } : { backgroundImage: `url()` }}
                >
                    <Icon name="play" />
                    <div className='video-modal'></div>
                </div>
                :
                <div className={`image-preview`} style={props.videoPreview ? { backgroundImage: `url(${props.videoPreview})` } : { backgroundImage: `url()` }} onClick={()=> window.open(props.linkPreview, "_blank")}></div>
              
            
    );
};

export default VideoYoutube;
