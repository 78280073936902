import ScrollToTop from '../../utils/scroll-to'
import './container.scss'



const Container = ({...props}) => {
    return (
        <div className={`container ${props.pageKey}`}><ScrollToTop /><div className='page'>{props.children}</div></div> 
    )
}
//{props.footer ? props.footer : null}

export default Container